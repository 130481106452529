/**
 * A control for loading a modal's content via ajax, with loading and error states.
 *
 * =====================
 * Basic Usage
 * =====================
 *
 * 1. Create a button or link that will trigger loading the modal.
 *    It must have a "modal_path" attribute containing a path to load
 *    into the modal when clicked.
 *
 *    <%= link_to 'Click to load modal', '#', :modal_path => example_modal_content_path, :id => 'my_link' %>
 *
 * 2. On document ready, initialize the modal:
 *
 *    var modal = new AjaxModal('my_modal', {
 *      title: 'My Modal',
 *      openWith: '#my_link',
 *    });
 *
 *    The modal has the following API:
 *
 *    modal.close();
 *
 *    modal.registerEvents();
 *      Re-registers events on all buttons which should open the modal. You'll need to call
 *      this if the modal has already been initialized and you've later added new buttons to
 *      the page dynamically.
 *
 *    modal.getTitle();
 *    modal.setTitle(value);
 *
 * =====================
 * Configuration
 * =====================
 *
 * The following options are also accepted, but not required:
 *
 *      config: { width: 300 }
 *        Custom modal configuration overrides.
 *
 *      openWithFilter: undefined
 *        A child selector to filter open events by. Useful when wanting to
 *        bind the event listener higher than the dom element that the event
 *        fires from.
 *
 *      onContentLoad: function(contentWrapper, ajaxModal) { }
 *        A function which is invoked after the modal content is loaded. It receives two
 *        arguments: first is a JQ object of the wrapper surrounding the newly
 *        placed html content, the second is the AjaxModal itself. The value of 'this' is
 *        the button which opened the modal.
 *
 *      validateWith: function() { }
 *        A function to perform validation with on the modal's form when submitted. The
 *        value of 'this' is the form.
 *
 *      onSubmit: function() { }
 *        A function to invoke when the modal's form is submitted and passes validation.
 *        The value of 'this' is the form.
 *
 *      preload: true
 *        *** WARNING: This feature broke with the Jquery upgrade, yet to be debugged/fixed ***
 *        Preloads the modal; defaults to false. If there is more than one button
 *        specified, only the first will be preloaded.
 *
 *      refreshEachOpen: true
 *        Forces a refresh of the modal content each time it is opened, even if the same
 *        button is used (normally the response is cached for the second time).
 *
 *      centerOnLoad: true
 *        Centers the modal after loading the ajax content; defaults to false. Useful
 *        for larger modals which would otherwise end up too low on the page.
 *
 *      openEvent: 'change'
 *        Defaults to click. This is the event that gets bound to 'openWith' to open
 *        the modal.
 *
 *      classes: []
 *        An array of extra classes to add to the modal's container element.
 *
 * =====================
 * Features
 * =====================
 *
 * Auto-focus/select
 *
 *   Add class 'autofocus' to the text field in your modal which should be focused.
 *   You can also add class 'autoselect' if the text itself should be selected.
 *
 * Scrolling
 *
 *   The modal height will be automatically adjusted to account for the window height,
 *   so long as there is a div with class 'scrollable' in the modal content.
 *
 * Chosen
 *
 *   Chosen fields are initialized using Config.Chosen.Default. If a select also has
 *   class 'chzn-allow-deselect', it will use Config.Chosen.AllowDeselect instead.
 *
 * Select2
 *
 *   Non-select inputs marked with class 'select2' and with an attribute 'json' or 'data-url' will be
 *   initialized using the value of the attribute.
 */
import _ from 'underscore';
import Ajax from 'legacy/ajax';
import Config from 'legacy/config';
import $ from 'legacy/jquery';
import tinyMCE from 'tinymce';
import { Validate } from 'legacy/validation';
import Handlebars from 'legacy/handlebars';
import { contentSecurityPolicy } from './util/prevent_default_placeholder_anchor_tags';
// @ts-expect-error need to migrate to TS
import { findAndConvertMetadata } from './content_security_policy_helper';

type OnContentLoadFunc = (
  this: any,
  contentWrapper: JQuery,
  ajaxModal: AjaxModal
) => void;

type Options = {
  openWith: string;
} & Partial<{
  openWithFilter: any;
  openEvent: 'change' | 'click';
  useRedesign: boolean;
  classes: Array<string>;
  config: any;
  data: (() => any) | any;
  bufferSpace: number;
  title: string;
  subtitle: string;
  path: string;
  errorMessage: string;
  preload: true;
  refreshEachOpen: boolean;
  destroyOnClose: boolean;
  onClose: () => void;
  onContentLoad: OnContentLoadFunc | Array<OnContentLoadFunc>;
  centerOnLoad: boolean;
  onSubmit: (e: any) => void;
  validateWith: JQueryValidateFunc;
}>;

class AjaxModal {
  id: string;
  modal: JQuery;
  options: Options;
  header: JQuery | undefined;
  title: string | undefined;
  subtitle: string | undefined;
  contentWrapper: JQuery;
  spinner: JQuery;
  loadingError: JQuery;
  currentUrl: string | null;
  openedBy: Element | JQuery | null | undefined;
  template =
    '<div id="{{id}}" class="modal">' +
    '<div class="modal-body">' +
    '<div class="spinner-container"></div>' +
    '<div class="loading-error">' +
    'Sorry, we encountered an error. Please <a href="https://support.greenhouse.io/direct">contact our support team</a> if the problem persists.' +
    '</div>' +
    '<div class="modal-content-wrapper"></div>' +
    '</div>' +
    '</div>';

  constructor(id: string, options: Options) {
    this.id = id;
    this.modal = $('#' + id);
    this.options = options || {};
    this.options.openEvent = this.options.openEvent || 'click';
    this.options.useRedesign = this.options.useRedesign || false;
    this.options.classes = this.options.classes || [];
    this.options.config = options.config || {};
    this.options.bufferSpace = this.options.bufferSpace || 0;

    if (this.options.useRedesign) {
      this.options.config.width = 980;
    }

    if (this.modal.length === 0) {
      this.modal = $(
        Handlebars.compile(this.template)({
          id: id,
          title: this.options.title,
          subtitle: this.options.subtitle,
        })
      );

      if (this.options.classes) {
        this.modal.addClass(this.options.classes.join(' '));
      }

      $(document.body).append(this.modal);
    }

    this.contentWrapper = this.modal.find('.modal-content-wrapper');
    this.spinner = this.modal.find('.spinner-container');
    this.loadingError = this.modal.find('.loading-error');
    this.currentUrl = null;

    this.registerEvents();

    if (this.options.preload) {
      const button = $(this.options.openWith)[0];
      this.loadContent(button);
    }
  }

  registerEvents() {
    this.modal
      .find('.x')
      .unbind('click')
      .click($.proxy(this.close, this))
      .on('click', contentSecurityPolicy.hrefPreventDefault);

    const $openWith = $(this.options.openWith);

    if ($openWith.length > 0) {
      // use a namespaced event to prevent unbinding the same event from another control
      const event = this.options.openEvent + '.' + this.id;
      $openWith
        .unbind(event)
        .on(event, this.options.openWithFilter, (e: JQueryEventObject) => {
          const button = e.currentTarget;
          if (!$(this).hasClass('disabled-button')) {
            this.open(button);
          }
          return false;
        });
    }

    if ($openWith.attr('href') === '#') {
      $openWith.on('click', contentSecurityPolicy.hrefPreventDefault);
    }

    const options = this.options;
    if (typeof options.onClose === 'function') {
      this.modal.on('dialogclose', options.onClose);
    }

    this.modal.on('greenhouse.heightChanged', () => this.adjustHeight());

    $(window).on('resize', () => this.adjustHeight());
  }

  open(button: Element | JQuery) {
    this.modal.dialog(Config.Modal.custom(this.options.config));
    this.header = this.modal.siblings('.ui-dialog-titlebar');

    // The widget markup is only created once and shared by all modals.
    // Any changes should be reverted.
    //  Add subtitle element
    this.header.find('.subheader').remove();
    this.header.find('.ui-dialog-title').before('<span class="subheader">');

    // Add redesign class to outermost wrapper
    if (this.options.useRedesign) {
      this.modal.parents('.ui-dialog').addClass('modal-redesign');

      if (!this.options.subtitle) {
        this.modal.parents('.ui-dialog').find('a.x').css('padding', '20px');
      }
    }

    // Add x class to preserve any events bound to it
    $('.ui-dialog-titlebar-close').addClass('x').attr('href', '#');
    this.header
      .find('.x')
      .unbind('click')
      .click($.proxy(this.close, this))
      .on('click', contentSecurityPolicy.hrefPreventDefault);

    this.modal.find('.x').blur();

    this.adjustHeight();

    this.loadContent(button);
  }

  close() {
    // Remove redesign class from shared modal wrapper
    this.modal.parents('.ui-dialog').removeClass('modal-redesign');

    if (this.options.refreshEachOpen && this.options.destroyOnClose) {
      this.modal.dialog('close').remove();
    } else if (this.modal.hasClass('ui-dialog-content')) {
      this.modal.dialog('close');
    }
  }

  /**
   * Adjusts the height of the modal to account for the window height by enabling
   * scrolling on whichever div has class 'scrollable'.
   */
  adjustHeight() {
    const scrollableContent = this.modal.find('.scrollable');

    if (scrollableContent.length === 1) {
      this.modal.css('maxHeight', 'none');
      scrollableContent.css('height', 'auto');
      const borderThickness = 1;
      const height = this.modal.height() + borderThickness;
      // headerHeight actually varies, but 50 is the max to be safe
      const headerHeight = 50;
      const bufferSpace = this.options.bufferSpace || 0;
      const maxHeight = $(window).height() - 60 - headerHeight - bufferSpace;

      if (height > maxHeight) {
        this.modal.css('maxHeight', maxHeight);
        scrollableContent.css({
          height: maxHeight - (height - scrollableContent.height()),
          overflow: 'auto',
        });
      }
    }

    if (this.options.centerOnLoad) {
      this.center();
    }
  }

  loadContent(button: Element | JQuery) {
    const $button = $(button);
    const modalPath =
      $button.attr('modal_path') ||
      $button.data('modal_path') ||
      $button.data('modal-path') ||
      this.options.path;
    const newUrl = modalPath || $button.val();
    const title =
      $button.attr('modal_title') ||
      $button.data('modal-title') ||
      this.options.title;
    const subtitle = $button.attr('modal_subtitle');
    const spinner = this.spinner;
    const loadingError = this.loadingError;
    const contentWrapper = this.contentWrapper;
    const modal = this.modal;
    const options = this.options;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    let data;

    if (typeof this.options.data === 'function') {
      data = this.options.data();
    } else {
      data = this.options.data || {};
    }

    if (
      Validate.notBlank(newUrl) &&
      (options.refreshEachOpen || newUrl !== this.currentUrl)
    ) {
      this.currentUrl = newUrl;
      this.openedBy = button;

      if (title) {
        this.setTitle(title);
      }

      if (subtitle) {
        this.setSubtitle(subtitle);
      }

      contentWrapper.hide();
      loadingError.hide();
      spinner.show();

      Ajax.get({
        url: this.currentUrl,
        data: data,
        dataType: 'html',
        pendingMessage: null,
        disableFlash: true,
        success: afterLoad,
        error: onLoadError,
      });
    }

    function afterLoad(response: any) {
      // Necessary because TinyMCE would not load properly for FF after previously closing rejection modal
      if (typeof tinyMCE === 'object') {
        tinyMCE.triggerSave();
      }

      spinner.hide();
      contentWrapper.html(response);
      findAndConvertMetadata();
      contentWrapper
        .find('.chzn-select:not(.chzn-allow-deselect)')
        .chosen(Config.Chosen.Default);
      contentWrapper
        .find('.chzn-select.chzn-allow-deselect')
        .chosen(Config.Chosen.AllowDeselect);
      contentWrapper.find('.cancel').click($.proxy(that.close, that));
      contentWrapper
        .find('.select2[json]:not(select)')
        .each(function (i: number, input: HTMLElement) {
          const json = $(input).attr('json');
          $(input).select2({ tags: $.parseJSON(json) });
        });

      contentWrapper
        .find('.select2[data-url]:not(select)')
        .each(function (i: number, input: HTMLElement) {
          new Ajax.SelectBox({
            selectBox: $(input),
          });
        });

      if (
        typeof options.validateWith === 'function' &&
        typeof options.onSubmit === 'function'
      ) {
        modal
          .find('form')
          .validateOn('submit', options.validateWith, options.onSubmit);
      } else if (typeof options.onSubmit === 'function') {
        modal.find('form').on('submit', options.onSubmit);
      }

      if (typeof options.onContentLoad === 'function') {
        options.onContentLoad = [options.onContentLoad];
      }

      if ($.isArray(options.onContentLoad)) {
        _.each(
          options.onContentLoad as Array<OnContentLoadFunc>,
          function (func) {
            func.call(that.openedBy, contentWrapper, that);
          }
        );
      }

      window.setTimeout(autofocus, 0);

      contentWrapper.show();

      contentWrapper
        .find('link[rel="stylesheet"]')
        .on('load', that.adjustHeight.bind(that));
      that.adjustHeight();

      function autofocus() {
        modal.find('.autofocus').first().focus();
        modal.find('.autoselect').first().select();
      }
    }

    function onLoadError(response: { status: number; responseText: string }) {
      if (typeof response === 'undefined') {
        if (that.options.errorMessage) {
          loadingError.text(that.options.errorMessage);
        }
      } else if (response.status === 404) {
        loadingError.text(response.responseText);
      }

      spinner.hide();
      loadingError.show();

      that.currentUrl = null;
      that.openedBy = null;
    }
  }

  setTitle(title: string) {
    this.modal.dialog('option', 'title', title);
  }

  getTitle() {
    return this.modal.dialog('option', 'title');
  }

  setSubtitle(subtitle: string) {
    this.header?.find('.subheader').text(subtitle);
  }

  center() {
    if (this.modal.hasClass('ui-dialog-content')) {
      this.modal.dialog('option', 'position', {
        my: 'center',
        at: 'center',
        of: window,
      });
    }
  }
}

export default AjaxModal;
