/**
 * TODO: Duplicated from app/assets/javascripts/shared/controls/gh_fullcalendar.js
 */
import moment from 'moment';
import $ from 'legacy/jquery';
// requires jQuery to be loaded; set `this` to window for backwards compatibility
import 'imports-loader?type=commonjs&imports=single|moment|moment&wrapper=window&additionalCode=var%20define%20=%20false;%20var%20exports%20=%20false;!../../../../assets/javascripts/3rdparty/fullcalendar/fullcalendar-v2.9.1';
import _ from 'underscore';
import { addMomentLocale } from 'shared/utils/moment_locale_helper';

addMomentLocale();

const FullCalendar = {
  eventToInputs: function (event: any, namePrefix: string) {
    return _.map(['start', 'end'], function (key) {
      return $('<input/>', {
        type: 'hidden',
        name: namePrefix + '[][' + key + ']',
        value: event[key].format(),
      });
    });
  },
  eventsToInputs: function (events: Array<any>, namePrefix: string) {
    return _.chain(events)
      .map(_.partial(FullCalendar.eventToInputs, _, namePrefix))
      .flatten()
      .value();
  },
  addRemoveButton: function (
    event: Event,
    eventElement: JQuery,
    onClick: (e: Event) => void
  ) {
    const container = $('<div></div>')
      .css('position', 'absolute')
      .css('top', '0px')
      .css('right', '2px')
      .css('z-index', 10);

    const button = $('<span>X</span>').on('click', function () {
      onClick(event);
    });

    container.prepend(button);
    $(eventElement).find('.fc-content').prepend(container);
  },
};

export default FullCalendar;
