/**
 * Dropdown control for loading office/departments through ajax and displaying it with tiering
 *
 * =====================
 * Basic Usage
 * =====================
 *
 * 1. Use the greenhouse helper tag to create the dropdown. It will be an input field that takes in the id,
 *    type (:office/:department), starting selected model, and the configurations (more info below)
 *
 *    <%= organizational_structure_select_tag :office_id, :office, selected_office, configurations %>
 *
 * 2. On document ready, initialize the dropdown:
 *
 *    var control = OrganizationalStructureControl.register('#office_id', { width: '300px', allowClear: false });
 *
 *    The control has the following API:
 *
 *    control.selectedText(); // Returns the text of the selected option
 *
 *    var control = OrganizationalStructureControl.find('#office_id'); //Returns the control of an already registered id
 *
 * =====================
 * Configuration
 * =====================
 *
 * The following configurations are set as data attributes on the organizational_structure_select_tag:
 *
 *      disable_ids: []
 *        Disable (gray out) all options with these ids. Useful for not allowing selecting nodes
 *        in the same branch.
 *
 *      display_only_ids_with_parents: []
 *        Hide all options that do not have these ids, but show parents who may not
 *        be in the array of IDs. Useful for permissions.
 *
 *      display_only_ids_without_parents: []
 *        Hide all options that do not have these IDs. This is stricter than display_only_ids_without_parents,
 *        so parents that don't have the IDs will not be shown, e.g. for restricting offices/depts for job creation
 *
 *      include_unattached_prospect_option: false
 *        Useful with the displayHiringPlans option. This will decide whether to display the
 *        'Prospects on no job' option
 *
 *      json: []
 *        An array of the organizational structure. If you pass this in, it will not do the ajax call and use this
 *
 *
 * The following configurations are set as attributes on the organizational_structure_select_tag:
 *
 *      value: Integer/String
 *        The id of the starting selected office/department
 *
 *      value_text: String
 *        Starting name of the starting selected office/department
 *
 *
 * =====================
 * Options
 * =====================
 *
 * The following options are set on the Javascript when you register. These are accepted, but not required:
 *
 *      lazyLoad: true
 *        Set to false if options should be fetched immediately on initialized
 *
 *      width: 300
 *        Set width of the dropdown
 *
 *      dropdownAutoWidth: true
 *        Adjust the width of the dropdown based on the content. You might want to set this to false
 *        if you are limited with space like in the prospecting plugin.
 *
 *      allowClear: true
 *        Allow dropdown to be cleared with the X button on the right
 *
 *      placeHolder: String
 *        Text place holder when nothing is selected
 *
 *      onChangeCallback: function() { }
 *        On change, this function will be called
 *
 *      clearCacheOnChange: false
 *        The dropdown options is cached so it does not make a ajax call on every open.
 *        If this is turned on, it will clear the cache every time the selected value is changed.
 *        This is useful with onChangeCallback when you need to update your dropdown.
 *
 *      displayHiringPlans: false
 *        This will display hiring plans that belongs to the organization structure.
 *        If the global filter is on, it will apply the changes to the dropdown to only show the jobs
 *        under that office/department
 *
 *      prependOptions: []
 *        Given an array of json with the key id and hash, options will be created with that data and prepended
 *        to the top of the dropdown
 *
 * =====================
 * Features
 * =====================
 *
 * Caching
 *
 *   The options are cached by type and if it includes hiring plan. The cacheKey is what the options
 *   are cached by. The response also goes through filterResponse to filter/manipulate the data
 *   from all the configurations and then cached so that is not done every time the dropdown is opened.
 *
 * Search
 *
 *   The search will filter the options with filterOptionsWithSearch. It will recursively look
 *   through all the children to find if it should display the parent.
 *
 * Auto-hide empty parent
 *
 *   If there is nothing selectable under a parent node, we will automatically hide the parents
 *   to only show selectable options.
 *
 */

// eslint-disable-next-line no-restricted-imports
import _ from 'underscore';
import Greenhouse from 'legacy/greenhouse';
import $ from 'legacy/jquery';
// eslint-disable-next-line @typescript-eslint/no-unused-vars -- used in eval
import Routes from 'legacy/routes';
import StringUtil from 'legacy/string_util';

const organizationalStructureControls = [];
const organizationalStructureCache = {};
const organizationalStructureCacheWithParents = {};

const OrganizationalStructureControl = (function () {
  // eslint-disable-next-line no-shadow
  function OrganizationalStructureControl(id, options) {
    this.id = id;
    this.dropdown = $(id);
    this.options = options || {};
    this.type = this.dropdown.attr('type');
  }

  OrganizationalStructureControl.prototype.loadOptions = function () {
    const that = this;
    const options = this.options;
    const containerCss = options.containerCss;
    const allowClear = options.hasOwnProperty('allowClear')
      ? options.allowClear
      : true;
    const lazyLoad = options.hasOwnProperty('lazyLoad')
      ? options.lazyLoad
      : true;
    const cacheKey = this.type + options.displayHiringPlans;
    const noResultsText = options.hasOwnProperty('noResultsText')
      ? options.noResultsText
      : Greenhouse.Translation.t('select.noResultsText');
    const searchText = options.hasOwnProperty('searchText')
      ? options.searchText
      : Greenhouse.Translation.t('select.searchText');
    const dropdownAutoWidth = options.hasOwnProperty('dropdownAutoWidth')
      ? options.dropdownAutoWidth
      : true;

    this.dropdown.select2({
      placeholder: placeHolderText(),
      dropdownAutoWidth: dropdownAutoWidth,
      allowClear: allowClear,
      minimumResultsForSearch: 3,
      containerCss: containerCss,
      width: options.width || '100%',
      multiple: options.multiple || false,
      formatSearching: searchText,
      formatNoMatches: noResultsText,
      initSelection: function (element, callback) {
        const $element = $(element);

        const value = $element.attr('value');
        const valueText =
          $element.attr('value_text') || $element.data('value-text');

        const defaultValues = $element.attr('default_values');

        if (value && valueText) {
          callback({ id: value, text: valueText });
        } else if (defaultValues) {
          callback(JSON.parse(defaultValues));
        }
      },
      query: function (query) {
        const cache = organizationalStructureCache[cacheKey];

        if (cache && !that.dropdown.data('json')) {
          populateDropdown(query, cache);
        } else {
          loadData(function (data) {
            populateDropdown(query, data);
          });
        }
      },
    });

    if (options.onChangeCallback) {
      this.dropdown.on('change', options.onChangeCallback);
    }

    if (!lazyLoad) {
      const dropdown = this.dropdown;

      loadData(function () {
        dropdown.trigger('change');
      });
    }

    if (options.clearCacheOnChange) {
      delete organizationalStructureCache[cacheKey];
    }

    function loadData(callback) {
      if (that.dropdown.data('json')) {
        loadDataThroughJSON(callback);
      } else {
        loadDataThroughAjax(callback);
      }
    }

    function loadDataThroughJSON(callback) {
      callback(cacheResponse(JSON.parse(that.dropdown.attr('data-json'))));
    }

    function loadDataThroughAjax(callback) {
      const data = {};

      if (options.displayHiringPlans) {
        data.display_hiring_plans = true;
      }

      $.ajax({
        // eslint-disable-next-line no-eval
        url: eval('Routes.' + that.type + 's_path'),
        data: data,
        dataType: 'json',
        success: function (response) {
          callback(cacheResponse(response));
        },
      });
    }

    function cacheResponse(data) {
      if (!that.dropdown.data('json')) {
        organizationalStructureCache[cacheKey] = data;
      }

      if (
        options.includeParents &&
        organizationalStructureCacheWithParents[cacheKey] === undefined
      ) {
        cloneCacheAndAddParentReference();
      }

      return data;
    }

    function cloneCacheAndAddParentReference() {
      // We need to clone the cache object because of the deepClone function which calls JSON.stringify.
      // Since we are creating an object below with a circular reference,
      // (recursively passing the parent object as a reference to each child object) JSON.stringify will error.
      organizationalStructureCacheWithParents[cacheKey] = jQuery.extend(
        true,
        {},
        organizationalStructureCache[cacheKey]
      );

      traverseAndAddParentReference(
        organizationalStructureCacheWithParents[cacheKey]
      );
    }

    function traverseAndAddParentReference(data) {
      data.init = function () {
        for (const i in this) {
          if (typeof this[i] === 'object') {
            this[i].init = this.init;
            this[i].init();
            this[i].parent = this;
          }
        }
        return this;
      };

      data.init();
    }

    function placeHolderText() {
      if (options.placeHolder) {
        return options.placeHolder;
      } else {
        const type = options.displayHiringPlans
          ? 'Hiring Plan'
          : StringUtil.capitalize(that.type);

        return allowClear ? 'No ' + type : 'Select one';
      }
    }

    function populateDropdown(query, data) {
      query.callback({ results: filterData(data) });

      // eslint-disable-next-line no-shadow
      function filterData(data) {
        const searchTerm = query.term;
        const defaultValues = that.selectedText();
        const disableOption = that.dropdown.data('disable-ids');
        const displayOnlyIdsWithParents = that.dropdown.data(
          'display-only-ids-with-parents'
        );
        const displayOnlyIdsWithoutParents = that.dropdown.data(
          'display-only-ids-without-parents'
        );
        const includeUnattachedProspectOption = that.dropdown.data(
          'include-unattached-prospect-option'
        );
        let clonedData = deepClone(data);

        if (searchTerm !== '') {
          clonedData = filterOptionsWithSearch(clonedData);
        }

        if (options.multiple && defaultValues.length > 0) {
          clonedData = filterChildrenOfSelected(clonedData);
        }

        if (displayOnlyIdsWithoutParents) {
          clonedData = filterDisplayOnlyWithoutParents(clonedData);
        }

        if (displayOnlyIdsWithParents) {
          clonedData = filterDisplayOnlyWithParents(clonedData);
        }

        if (disableOption) {
          clonedData = disableOptions(clonedData);
        }

        if (options.prependOptions) {
          options.prependOptions.forEach(function (hash) {
            clonedData.unshift({ id: hash.id, text: hash.text });
          });
        }

        clonedData = filterEmptyParent(clonedData);

        if (includeUnattachedProspectOption) {
          clonedData.unshift({ id: '-1', text: 'Prospects on no job' });
        }

        return clonedData;

        function disableOptions(dataArray) {
          return _.map(dataArray, function (object) {
            // eslint-disable-next-line eqeqeq
            object.disabled = object.id == disableOption;

            if (object.children) {
              object.children = disableOptions(object.children);
            }

            return object;
          });
        }

        function filterEmptyParent(dataArray) {
          return dataArray.filter(function (object) {
            if (object.children) {
              const filteredData = filterEmptyParent(object.children);
              object.children = filteredData;

              return filteredData.length > 0;
            } else {
              return !!object.id;
            }
          });
        }

        // for Job Creation tasks like Ramp Up Create and Job Info edit, we don't want to display parents
        // unless their IDs are specified
        function filterDisplayOnlyWithoutParents(dataArray) {
          const returnedArray = [];

          dataArray.forEach(function (object) {
            findObjectToDisplay(object);
          });

          return returnedArray;

          function findObjectToDisplay(object) {
            if (displayOnlyIdsWithoutParents.indexOf(object.id) >= 0) {
              returnedArray.push(object);
            } else if (object.children) {
              object.children.forEach(function (child) {
                findObjectToDisplay(child);
              });
            }
          }
        }

        function filterDisplayOnlyWithParents(dataArray) {
          return dataArray.filter(function (object) {
            if (object.children) {
              const filteredData = filterDisplayOnlyWithParents(
                object.children
              );
              object.children = filteredData;

              return (
                displayOnlyIdsWithParents.indexOf(object.id) >= 0 ||
                filteredData.length > 0
              );
            } else {
              return displayOnlyIdsWithParents.indexOf(object.id) >= 0;
            }
          });
        }

        function filterOptionsWithSearch(dataArray) {
          if (typeof dataArray === 'undefined') {
            return false;
          }

          return dataArray.filter(function (value) {
            const childrenMatch = filterOptionsWithSearch(value.children);

            if (childrenMatch.length > 0) {
              value.children = filterOptionsWithSearch(childrenMatch);
            }

            return (
              value.text.toLowerCase().match(searchTerm.toLowerCase()) ||
              childrenMatch.length > 0
            );
          });
        }

        function filterChildrenOfSelected(dataArray) {
          return dataArray.filter(function (value) {
            const children = value.children || [];
            const valueNotSelected = defaultValues.indexOf(value.text) === -1;

            if (children.length > 0) {
              value.children = filterChildrenOfSelected(children);
            }

            return valueNotSelected;
          });
        }
      }
    }

    function deepClone(object) {
      return JSON.parse(JSON.stringify(object));
    }
  };

  function traverseAndAddParentIdsToArray(data, id, parentIds) {
    // eslint-disable-next-line guard-for-in
    for (const i in data) {
      if (i === 'id' && data[i] === id) {
        traverseAndFindParentIds(data, parentIds);
      }

      if (data[i] !== null && typeof data[i] === 'object' && i !== 'parent') {
        traverseAndAddParentIdsToArray(data[i], id, parentIds);
      }
    }
  }

  function traverseAndFindParentIds(data, idArray) {
    for (const i in data) {
      if (i === 'id' && data.hasOwnProperty('parent')) {
        // We are calling .parent twice because the first .parent gives you the children array,
        // and the second .parent gives you the actual object with the id property.
        if (data.parent.parent !== undefined) {
          idArray.push(data.parent.parent.id);
          traverseAndFindParentIds(data.parent.parent, idArray);
        }
      }
    }
  }

  function findSelectedIds(data) {
    if (!data) {
      return [];
    } else if (data.hasOwnProperty('id')) {
      return [data.id];
    } else if (Array.isArray(data)) {
      return _.map(data, function (obj) {
        return obj.id;
      });
    } else {
      return [];
    }
  }

  OrganizationalStructureControl.prototype.selectedText = function () {
    const data = this.dropdown.select2('data');

    if (!data) {
      return 'None';
    } else if (Array.isArray(data)) {
      return _.map(data, function (obj) {
        return obj.text;
      });
    } else if (data.hasOwnProperty('text')) {
      return data.text;
    } else {
      return '';
    }
  };

  OrganizationalStructureControl.prototype.setSelectedIdsAndChildrenIds =
    function () {
      const parentIds = [];
      const selectedData = this.dropdown.select2('data');

      const selectedIds = findSelectedIds(selectedData);

      if (this.options.includeParents) {
        selectedIds.forEach(function (id) {
          traverseAndAddParentIdsToArray(
            organizationalStructureCacheWithParents,
            id,
            parentIds
          );
        });
      }

      this.selectedIdsAndChildrenIdsArray = _.union(parentIds, selectedIds);
    };

  OrganizationalStructureControl.hasAccessToOfficeDept = function (
    customFieldDeptIds,
    customFieldOfficeIds,
    hiringPlanDeptIds,
    hiringPlanOfficeIds
  ) {
    if (
      _.intersection(customFieldOfficeIds, hiringPlanOfficeIds).length > 0 &&
      customFieldDeptIds.length === 0
    ) {
      return true;
    } else if (
      _.intersection(customFieldDeptIds, hiringPlanDeptIds).length > 0 &&
      customFieldOfficeIds.length === 0
    ) {
      return true;
    } else if (
      _.intersection(customFieldOfficeIds, hiringPlanOfficeIds).length > 0 &&
      _.intersection(customFieldDeptIds, hiringPlanDeptIds).length > 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  OrganizationalStructureControl.register = function (id, options) {
    const control = new this(id, options);

    if (control.dropdown.length > 0 && control.dropdown.is('input')) {
      control.loadOptions();
      organizationalStructureControls.push(control);
    }

    return control;
  };

  OrganizationalStructureControl.find = function (id) {
    return _.find(organizationalStructureControls, function (object) {
      // eslint-disable-next-line eqeqeq
      return object.id == id;
    });
  };

  OrganizationalStructureControl.registerEventsForMultiSelect = function (
    checkboxSelector,
    multiSelectInputSelector
  ) {
    const checkbox = $(checkboxSelector);
    const multiSelectInput = $(multiSelectInputSelector);

    // When checkbox for all offices or all departments is checked,
    // disable multi select input field.
    checkbox.on('change', function () {
      if ($(this).is(':checked')) {
        multiSelectInput.val(null).trigger('change');
        multiSelectInput.prop('disabled', true);
      } else {
        multiSelectInput.prop('disabled', false);
      }
    });

    // When a multi select option is selected,
    // uncheck the all offices or all departments checkbox.
    multiSelectInput.on('change', function () {
      if (checkbox.is(':checked') && multiSelectInput.val().length > 0) {
        checkbox.prop('checked', false);
      }
    });
  };

  return OrganizationalStructureControl;
})();

export default OrganizationalStructureControl;
