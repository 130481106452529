const existingJQuery = (window as any).$;

const $ =
  existingJQuery || require('../../../../assets/javascripts/3rdparty/jquery');

if (!existingJQuery) {
  (window as any).$ = (window as any).jQuery = $;
  require('legacy/3rdparty/chosen.jquery');
  require('../../../../assets/javascripts/3rdparty/select2-v3.5.2.min');
  // requires jQuery to be loaded; set `this` to window for backwards compatibility
  require('imports-loader?type=commonjs&wrapper=window&additionalCode=var%20define%20=%20false;%20var%20exports%20=%20false;!../../../../assets/javascripts/3rdparty/jquery-ui.min');
  require('../../../../assets/javascripts/3rdparty/jquery_ujs');
  require('../../../../assets/javascripts/shared/lib_ext/jq_ext');
  require('legacy/3rdparty/jquery-textntags');
  require('imports-loader?type=commonjs&wrapper=window&additionalCode=var%20define%20=%20false;%20var%20exports%20=%20false;!blueimp-file-upload/js/jquery.fileupload');
  require('../../mobile/components/gh_mobile_flyout');
  require('../../mobile/components/gh_mobile_tabs');
}

require('./contains_insensitive');

export default $;
