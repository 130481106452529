import _ from 'underscore';
import Ajax from 'legacy/ajax';
import $ from 'legacy/jquery';
import { Validate } from 'legacy/validation';

const SourceControlValidations = (function () {
  const source = function source() {
    const $requiredSourceField = $('.source-td.required:visible');
    if ($requiredSourceField.length > 0) {
      return [
        Validate.field(
          '.source-td.required [id$=source_id]',
          Validate.notBlank,
          'Source is required'
        ),
      ];
    } else {
      return [true];
    }
  };

  const referrer = function referrer() {
    const validations = [];

    const $requiredReferrerField = $('.referrer-td.required');

    if ($requiredReferrerField.length > 0) {
      if ($('.existing-referrers:visible').length > 0) {
        validations.push(
          Validate.field(
            '.existing-referrers input[id$=referred_by_id]',
            Validate.notBlank,
            'Referrer is required'
          )
        );
      }

      if ($('#referred_by:visible').length > 0) {
        validations.push(
          Validate.field(
            '[id$=referred_by]',
            Validate.notBlank,
            'Referrer is required'
          )
        );
      }
    }

    return validations;
  };

  const customSource = function (
    $customSource,
    $customSourceStrategy,
    getSources
  ) {
    const validations = [];
    if ($customSource.is(':visible')) {
      const customSourceNotEmpty = Validate.field(
        $customSource,
        Validate.notBlank,
        'Enter a name for your custom source'
      );
      validations.push(customSourceNotEmpty);

      if (customSourceNotEmpty) {
        validations.push(
          Validate.field(
            $customSource,
            Validate.notPresentIn(getSources()),
            'Source must be unique by source type'
          )
        );
      }

      validations.push(
        Validate.field(
          $customSourceStrategy,
          Validate.notBlank,
          'Select the type of your custom source'
        )
      );
    }

    return validations;
  };

  return {
    source: source,
    referrer: referrer,
    customSource: customSource,
  };
})();

const SourceControl = function (prefix) {
  const source = selector('source_id');
  const sourceRow = selector('source_id_row');
  const referredBy = selector('referred_by');
  const referredById = selector('referred_by_id');
  const referredByRow = selector('referred_by_row');
  const customSourceStrategyRow = selector('custom_source_strategy_row');
  const customSourceStrategy = selector('custom_source_strategy_id');
  const customSourceRow = selector('custom_source_row');
  const customSource = selector('custom_source');

  selector('add_source_link').on('click', showCustomSourceFields);
  selector('existing_source_link').on('click', showExistingSourceFields);

  $('.add-referrer-link').on('click', showCustomReferrer);
  $('.existing-referrers-link').on('click', showExistingReferrers);

  new Ajax.SelectBox({
    selectBox: referredById,
    config: {
      width: '260px',
      allowClear: true,
      formatResult: formatReferrerSelect,
      formatSelection: formatReferrerSelect,
      dropdownCssClass: 'referred-by-dropdown',
    },
  });

  function formatReferrerSelect(data) {
    // if data.text includes html tags, this will render the value as a string instead of stripping out the tags
    // for example: <script>debugger;</script> will render unchanged without executing the script
    // text rendered by rails behaves the same way
    const referrerText = $('<div />').text(data.text).html();

    if (!data.email) {
      return referrerText;
    }

    return (
      referrerText +
      ' <span class="dupe-email-note">(' +
      data.email +
      ')</span>'
    );
  }

  function showCustomReferrer() {
    referredByRow.find('.existing-referrers').hide();
    referredByRow.find('.custom-referrer').show();

    referredById.select2('val', '');

    $(this).parent().hide();
    $('.existing-referrers-link').parent().show();
  }

  function showExistingReferrers() {
    referredByRow.find('.existing-referrers').show();
    referredByRow.find('.custom-referrer').hide();

    referredBy.val('');

    $(this).parent().hide();
    $('.add-referrer-link').parent().show();
  }

  function selector(id) {
    return $('#' + (prefix ? prefix + '_' : '') + id);
  }

  function sources() {
    const selectedStrategyName = customSourceStrategy
      .find('option:selected')
      .html();
    const sourceOptions = source.find(
      'optgroup[label="' + selectedStrategyName + '"] option'
    );

    return _.map(sourceOptions, function (s) {
      return $(s).html();
    });
  }

  function showExistingSourceFields() {
    customSourceStrategyRow.hide();
    customSourceRow.hide();

    customSourceStrategy.val('').trigger('liszt:updated');
    customSource.val('');
    Validate.clearError(customSource);
    Validate.clearError(customSourceStrategy);

    sourceRow.show();
  }

  function showCustomSourceFields() {
    sourceRow.hide();
    $('form').find('.referrer').toggle(false);

    source.val('').trigger('liszt:updated');

    customSourceStrategyRow.show();
    customSourceRow.show();
  }

  this.validateFunc = function () {
    return function () {
      let validations = [];

      validations = validations.concat(SourceControlValidations.source());
      validations = validations.concat(SourceControlValidations.referrer());
      validations = validations.concat(
        SourceControlValidations.customSource(
          customSource,
          customSourceStrategy,
          sources
        )
      );

      return validations;
    };
  };
};

export default SourceControl;
