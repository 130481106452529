/*
  TODO: Duplicated from app/assets/javascripts/shared/time_zones.js
*/

// @ts-expect-error - TS7016
import jstz from '../../../assets/javascripts/3rdparty/shared/jstz.min';

// jstz uses the browser's international api if it's available - but that API returns
// names our server doesn't know about, such as "America/Toronto".
const detect = function () {
  function withoutInternationalAPI(callback: () => string) {
    const oldInternationalApi = window.Intl;
    (window as any).Intl = undefined;

    const value = callback();

    window.Intl = oldInternationalApi;

    return value;
  }

  return withoutInternationalAPI(function () {
    return jstz.determine().name();
  });
};

const TimeZones = { detect };
export default TimeZones;
