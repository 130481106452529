const Effects = {
  Slide: {
    remove: function (element: JQuery, complete: () => void) {
      element.animate({ opacity: '0' }, 100);
      element.slideUp(200, function () {
        $(this).remove();
        if (complete) {
          complete();
        }
      });
    },
  },

  flash: function (element: JQuery | string) {
    const oldBackgroundColor = $(element).css('background-color');
    $(element)
      .animate({ backgroundColor: '#e2f1fa' }, 1500, 'easeOutBack')
      .animate({ backgroundColor: oldBackgroundColor }, 1500, function () {
        $(this).css({ backgroundColor: '' });
      });
  },
};

export default Effects;
